<template>
  <div>
    <!-- Calendly inline widget begin -->
    <div class="calendly-inline-widget" data-url="https://calendly.com/lightningsites-sales/builder" style="min-width:320px;height:630px;"></div>
    <!-- Calendly inline widget end -->
  </div>
</template>

<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>

<script>
export default {
  data() {
    return {
      test: null
    }
  }
}
</script>

